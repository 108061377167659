import React from "react";
import "url-search-params-polyfill";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
import MetodicHeader from "../Metodic/Header";
// import { NavTabs } from "./NavTabs";
import { Item } from "../Metodic/Lesson";
import data from "./data.json";

const Items = ({ items }) => items.map((item) => <Item {...item} />);

const Content = () => {
  return <Items items={data} />;
};
const Metodic = (props) => {
  // const { classes, group } = props.match.params;
  return (
    <div className="page page__product">
      <Helmet>
        <title>Защита детей — Учебные пособия</title>
      </Helmet>
      <Header topic="workbook" />
      <div className="content">
        <MetodicHeader />
        <div className="section sc__common sc__met">
          <div className="container">
            <div className="sc__header">
              <div className="sc__title">Учебные пособия</div>
            </div>
            <div className="sc__content">
              {/* <div className="tabs__custom">
                <NavTabs active={classes} />
              </div>
              <div className="tab-content"> */}
              <Content />
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Metodic;
