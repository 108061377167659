import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import Product from "./Product";
import Article from "./Article";
import Video from "./Video";
import Text from "./Text";
import Metodic from "./Metodic/Page";
import Workbook from "./Workbook/Page";
import Search from "./Search";
import Interactive from "./Interactive";
import NotFound from "./Error";
import QuizNubGuru from "./QuizNubGuru";

export default class Main extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/product" component={Product} />
        <Route exact path="/metodic">
          <Redirect to="/metodic/junior" />
        </Route>
        <Route path="/metodic/:classes/:group" component={Metodic} />
        <Route path="/metodic/:classes" component={Metodic} />
        <Route path="/workbook" component={Workbook} />
        <Route exact path="/search" component={Search} />
        <Route path="/safekidsinfo" component={Article} />
        <Route path="/digest" component={Article} />
        <Route path="/article" component={Article} />
        <Route path="/video" component={Video} />
        <Route path="/text" component={Text} />
        <Route path="/interactive" component={QuizNubGuru} />
        <Route path="/category/interactive" component={Interactive} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}
